body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.__react_component_tooltip.show {
  opacity: 1 !important;
}

/* Load custom fonts */
@font-face {
  font-family: 'Inter';
  src: url(./fonts/Inter/static/Inter-Black.ttf);
  src: url(./fonts/Inter/static/Inter-Regular.ttf);
}
@font-face {
  font-family: 'Calistoga';
  src: url(./fonts/Calistoga/Calistoga-Regular.ttf);
}
@font-face {
  font-family: 'Calistoga';
  font-weight: 500;
  src: url(./fonts/Calistoga/Calistoga-Regular.ttf);
}

@font-face {
  font-family: 'Calistoga';
  font-weight: bold;
  src: url(./fonts/Calistoga/Calistoga-Regular.ttf);
}
