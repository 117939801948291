.shortAddress {
  display: flex;
  flex-flow: row nowrap;
  gap: 6px;
  align-items: center;
}

.shortAddress > div {
  padding-top: -2px;
}

.shortAddress span {
  letter-spacing: 0.2px;
  font-family: 'Calistoga';
  font-weight: bold;
}
