.container {
  position: relative;
  text-align: center;
  color: white;
  overflow: hidden;
}

.overlay {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: radial-gradient(circle at center, transparent 58%, white 60%); /* Set radial-gradient to faded edges */
}

.card{
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
      transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
  padding: 14px 80px 18px 36px;
  cursor: pointer;
}

.card:hover{
     transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.videoSection {
  padding-top: 4rem !important;
  padding-bottom: 2rem 0 !important;
}

.iosSection {
  padding-top: 4rem;
  padding-bottom: 0 !important;
}

.textWrapper {
  padding-right: 2rem;
}

.textWrapper h1 {
  font-family: 'Calistoga';
  font-size: 56px;
}

.textWrapper p {
  font-size: 1.2rem;
}

.youtubeSectionText {
  margin-left: 20px;
  padding-right: 0px;
}

.embedContainer {
  padding: 60% 0 0 0;
  position: relative;
}

.youtubeEmbedContainer {
  padding: 60% 0 0 0;
  position: relative;
}

.embedContainer iframe {
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  height: 350px;
}

.youtubeEmbedContainer iframe {
  position: absolute;
  top: 20px;
  left: 0;
  height: 360px;
  width: 100%;
}

.videoSubtitle {
  position: absolute;
  top: 365px;
}

.youtubeVideoSubtitle {
  position: absolute;
  top: 350px;
}

.videoSubtitle a {
  display: inline-block;
  text-decoration: underline;
  color: #6c757d;
  padding: 0;
}

.iosImgContainer {
  padding: 0;
  position: relative;
}

.iosImg {
  max-width: 100%;
  height: 35rem;
  bottom: 0;
}

.guildImgContainer {
  padding: 0;
  position: relative;
}

.guildImg {
  max-width: 100%;
  height: 35rem;
  bottom: 0;
}

.dlFromAppStoreImg {
  margin-top: 2rem;
  width: 10rem;
}

@media (max-width: 992px) {
  .container {
    padding-top:5%;
    padding-bottom:5%;
    margin-left:15%;
    margin-right:15%;
    width:70%;
  }

  .videoSection {
    padding: 0 !important;
  }

  .iosSection {
    padding-top: 0;
    padding-bottom: 2rem;
  }

  .textWrapper {
    margin-left: 15px;
    padding: 1rem;
  }

  .textWrapper h1 {
    font-size: 3.75rem;
    text-align: center;
    margin-top: 10%;
  }

  .embedContainer {
    padding: 100% 0 0 0 !important;
    margin-top: 10px;
    margin-bottom: 60px;
  }

  .youtubeEmbedContainer {
    padding: 100% 0 0 0 !important;
    margin-top: 10px;
    margin-bottom: 60px;
  }

  .embedContainer iframe {
    top: 0;
    height: 100%;
  }
  .youtubeEmbedContainer iframe {
    top: 0;
    height: 100%;
  }

  .videoSubtitle {
    top: 200px;
    padding: 2rem 2.5rem;
  }

  .iosImgContainer {
    display: none;
  }
}

@media (max-width: 576px) {
  .videoSubtitle {
    bottom: -30px;
  }
}

.nounsIntroLinks {
  color: #d63c5e;
}

.nounsIntroLinks:hover {
  color: #d63c5e;
}
