.card{
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
      transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
    padding: 18px 80px 18px 36px;
    cursor: pointer;
}

.card:hover{
     transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.heading {
  display: inline-block;
  font-weight: bold;
  font-size: 3rem;
}

.headerRow span {
  color: #8c8d92;
  font-size: 24px;
  font-family: 'Inter';
}

.headerRow h1 {
  color: #14161b;
  font-size: 56px;
  font-family: 'Calistoga';
}

.subheading {
  color: var(--brand-dark-green);
  font-family: 'Inter';
  font-weight: 500;
  font-size: 1.2rem;
}

.boldText {
  font-family: 'Inter';
  font-weight: bold;
}

p {
  padding-top: 1rem;
}

@media (max-width: 992px) {
  .section {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.wrapper {
  margin-left: auto;
  margin-right: auto;
}

.treasuryInfoCard {
  margin-bottom: 3rem;
  border-radius: 20px;
  border: 1px solid #e2e3e8;
}

.treasuryAmtWrapper {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.usdTreasuryAmt {
  padding-top: 0.2rem;
}

.ethTreasuryAmt {
  display: flex;
  height: 3rem;
  min-width: 9rem;
  padding-top: 0.2rem;
}

.ethTreasuryAmt h1 {
  font-size: 36px;
  font-family: 'Inter';
}

.usdBalance {
  font-size: 36px;
  font-family: 'Inter';
}

.ethSymbol {
  font-family: 'Calistoga' !important;
  margin-right: 0.5rem;
}

.usdBalance {
  color: var(--brand-gray-light-text);
  font-family: 'Inter';
}

.treasuryInfoText {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-family: 'Calistoga';
  font-weight: 500;
}

@media (min-width: 992px) {
  .treasuryAmtWrapper {
    border-right: 2px solid #e2e3e8;
  }

  .ethTreasuryAmt {
    border-right: 2px solid #e2e3e8;
  }
}
