.walletButton {
  border: none;
  margin: 5px;
  padding: 5px 20px;
  border-radius: 5px;
  background-color: rgba(211, 211, 211, 0.664);
  color: var(--brand-black);
  border: none;
  font-family: 'Inter' !important;
}
.walletButton img {
  margin-right: 10px;
  border-radius: 5px;
}
.walletButton:hover,
.walletButton:active,
.walletButton:focus,
.walletButton:disabled {
  outline: none !important;
  box-shadow: none !important;
  background-color: lightgray !important;
  color: var(--brand-dark-green);
  color: white;
}
