.centeredLogo {
  position: absolute;
  top: calc(50vh - 10rem);
  left: calc(50vw - 5rem);
  width: 10rem;
  background: url('../../assets/logo.png') center center / cover;
  z-index: 10;
}

.loaderContainer {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loader {
    position: absolute;
    top: calc(50vh - 13rem);
    left: calc(50vw - 8rem);
    width: 16rem;
    height: 16rem;
    border-radius: 50%;
    background: linear-gradient(#f07e6e, #84cdfa, #5ad1cd);
    animation: animate 1.2s linear infinite;
    z-index: 1;
}

@keyframes animate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader span {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: linear-gradient(#f07e6e, #84cdfa, #5ad1cd);
}

.loader span:nth-child(1) {
    filter: blur(5px);
}

.loader span:nth-child(2) {
    filter: blur(10px);
}

.loader span:nth-child(3) {
    filter: blur(25px);
}

.loader span:nth-child(4) {
    filter: blur(50px);
}

.loader::after {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    background: white;
    border: solid white 10px;
    border-radius: 50%;
    z-index: 1;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.centerScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.navBarBrand {
  position: relative;
  z-index: 2;
  padding: 0.5rem 0rem;
  transition: all 0.15s ease-in-out;
}

.navBarBrand:hover {
  transform: scale(0.95);
}

.navBarLogo {
  width: 80px;
  height: 80px;
}

.navBarCustom {
  padding-bottom: 1rem;
}

@media (max-width: 992px) {
  .navBarLogo {
    width: 75px;
    height: 75px;
  }

  .navBarCustom {
    margin-bottom: -1px;
    margin-right: -5px;
    padding-bottom: 0rem;
  }
}

/* connect button displays differently to default NavBarItems, thus the !importants */
.connectBtn {
  cursor: pointer;
}

.connectBtn:hover,
.connectBtn:active,
.connectBtn:focus {
  cursor: pointer !important;
}

.disconnectBtn {
  cursor: pointer;
  font-family: 'Inter';
  font-weight: medium;
}

.disconnectBtn:hover,
.disconnectBtn:active,
.disconnectBtn:focus {
  cursor: pointer !important;
}

.greenStatusCircle {
  display: relative;
  width: 10px;
  height: 10px;
  background-color: #7fc59c;
  border-radius: 50%;
  margin-right: -30px;
  margin-left: 20px;
  margin-top: -20px;
  z-index: 3;
}

.nounsNavLink {
  display: inline-block;
}

.nounsNavLink,
.nounsNavLink:active,
.nounsNavLink:visited,
.nounsNavDropdownButton > button {
  font-family: 'Inter';
  font-weight: medium;
  font-size: 16px;
  padding: 0.3rem 0.3rem !important;
  color: black !important;
}

.nounsNavDropdownButton > button {
  background: transparent;
}

.testnetImg {
  width: auto;
  height: 45px;
}

.addressNavLink {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.brandAndTreasuryWrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.navBarToggle {
  border-radius: 10px;
  height: 44px;
  padding: 0.25rem 0.5rem;
  margin-right: 12px;
}

.nounsNavDropdownButton {
  height: 40px;
  border-radius: 10px;
  font-size: 16px;
  line-height: 16px;
  font-family: 'Inter';
  font-weight: bold;
  padding: 0px 12px 0px 12px;
  transition: all 0.125s ease-in-out;
  box-shadow: none !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.nounsNavDropdownButton button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border: none;
}
/* Unique styling for non-fa icon on Explore button */
@media (max-width: 992px) {
  .exploreButton svg {
    max-height: none;
    min-height: 40px;
    max-width: 40px;
  }
}
